
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const MobileMenu = {

    init: function() {

        this.$menu = $('[data-mobile-menu]');
        this.$toggle = $('[data-mobile-toggle]');
        this.$children = $('[data-mobile-menu-children]');

        if ( !this.$menu.length || !this.$toggle.length )
            return;

        this.$toggle.on( 'click', this.toggle.bind(this) );

        if ( this.$children.length )
            this.$children.on( 'click', this.toggleChildren.bind(this) );

        this.initMenu();

    },

	toggle() {

        this.active = !this.active;
        this.$menu.toggleClass('is-active');

        if ( this.active ) {
            disableBodyScroll( this.$menu[0] );
        } else {
            enableBodyScroll( this.$menu[0] );
        }

	},

    toggleChildren(e) {

        let $elem = $(e.currentTarget);
        let $active = this.$menu.find('.is-active');
        let $children = $elem.next();

        if ( $active.length && $active[0] != $children[0] )
            $active.removeClass('is-active');

        $children.toggleClass('is-active');

    },

    initMenu() {

        let $current = this.$menu.find('.is-current');

        if ( !$current.length )
            return;

        let $parent = $($current).parent();

        if ( $($parent).hasClass('mobileMenu_item_children') )
            $($parent).addClass('is-active');

    }
        
};

export default MobileMenu;