
import objectFitImages from 'object-fit-images';

export const Site = {

    init: function() {

        objectFitImages();

        this.loadLinks( 'email' );
        this.loadLinks( 'phone' );
        this.nonLinks();
        this.externalLinks();

        $('a[href^="#"]').on( 'click', this.sectionLinks.bind(this) );

    },

    loadLinks( $type = 'phone' ) {

    	let $links = $('[data-' + $type + '-link]');

        if ( !$links.length )
            return;

        $links.each( (index, elem) => {

            let $elem = $(elem);
            let $data = $elem.data( $type + '-link' );
            let $text = $elem.data( $type + '-text' );
            let $noscript = $elem.next( 'noscript' );
            
            if ( $text ) {
                $elem.html( $text );
            } else {
                $elem.html( $data );
            }
            
            if ( $type == 'email' ) {
                $elem.attr( 'href', 'mailto:' + $data );
            } else {
                $elem.attr( 'href', 'tel:' + $data.replace(/\s+/g, '') );
            }

            $elem.removeAttr( 'data-' + $type + '-link' );
            $elem.removeAttr( 'data-' + $type + '-text' );
            $elem.removeClass( 'js-item' );
            $noscript.remove();

        });

	},

    nonLinks() {

        let $links = $('a[href="#"]');

        if ( !$links.length )
            return;

        $links.each( (index, elem) => {

            elem.removeAttribute('href');

        } );

    },

    externalLinks() {

        let $links = $('a[href]');

        if ( !$links.length )
            return;

        $links.each( (index, elem) => {

            if ( !elem.host || elem.target || elem.href.includes('#') )
                return;

            if ( elem.href.includes('.pdf') || ( elem.host !== window.location.host ) )
                elem.target = '_blank';

        } );

    },

    sectionLinks(e) {

        e.preventDefault();

        let $href = $(e.currentTarget).attr('href');

        $('html,body').animate({
            scrollTop: $($href).offset().top
        }, 'slow');

    }

};

export default Site;