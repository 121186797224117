
export const BmiCalculator = {

    init: function() {

        this.$form = $('[data-bmi-calculator]');

        if ( !this.$form.length )
            return;

        this.$weight = this.$form.find('[data-bmi-calculator-weight]');
        this.$height = this.$form.find('[data-bmi-calculator-height]');
        this.$required = this.$form.find('[data-bmi-calculator-required]');
        this.$result = this.$form.find('[data-bmi-calculator-result]');
        this.$submit = this.$form.find('[data-bmi-calculator-submit]');
        this.$reset = this.$form.find('[data-bmi-calculator-reset]');
        
        if ( !this.$weight.length || !this.$height.length || !this.$required.length || !this.$result.length || !this.$submit.length || !this.$reset.length )
            return;

        this.$submit.on( 'click', this.calculateBmi.bind(this) );
        this.$reset.on( 'click', this.resetForm.bind(this) );

    },

    calculateBmi(e) {

        e.preventDefault();
        
        this.$isError = false;

        this.$required.each( (index, elem) => {
            this.validateField( elem );
        } );

        if ( this.$isError ) {
            this.$result.hide();
            return;
        }

        let $weight = parseInt( $(this.$form.find('[data-bmi-calculator-weight]')).val() );
        let $height = parseInt( $(this.$form.find('[data-bmi-calculator-height]')).val() );

        let $bmi = $weight / ( $height * $height ) * 10000;
        $bmi = Math.round($bmi * 10) / 10;

        this.$result.html( '<p>Your BMI is ' + $bmi + '.</p>' );

        if ( $bmiResults ) {
            for ( var i = 0; i < $bmiResults.length; i++ ) {
                if ( $bmi >= $bmiResults[i].start_range && $bmi <= $bmiResults[i].end_range ) {
                    this.$result.html( 'Your BMI is ' + $bmi + '. ' + $bmiResults[i].copy );
                    break;
                }
            }
        }

        this.$result.show();

    },

    validateField( $field ) {

        let $elem = $($field);

        if ( !$elem.val() ) {

            if ( !$elem.next().length )
                $elem.parent().append('<div class="bmiCalculator_form_error" data-bmi-calculator-error > * This field is required.</div>');
            
            this.$isError = true;

        } else {

            if ( $elem.next().length )
                $elem.next().remove();

        }

    },

    resetForm(e) {

        e.preventDefault();

        $('[data-bmi-calculator-error]').remove();
        this.$weight.val('');
        this.$height.val('');
        this.$result.hide();
        
    }

};

export default BmiCalculator;

