
window.$ = window.jQuery;

import '../styles/app.scss';

import { Site } from './modules/site';
import { Slider } from './modules/slider';
import { Steps } from './modules/steps';
import { BmiCalculator } from './modules/bmiCalculator';
import { MobileMenu } from './modules/mobileMenu';
import { TouchMenu } from './modules/touchMenu';

Site.init();
Slider.init();
Steps.init();
BmiCalculator.init();
MobileMenu.init();
TouchMenu.init();