
export const Slider = {

    init: function() {

        this.$slider = $('[data-slider]');

        if ( !this.$slider.length )
            return;

        this.$autoSlide = null;
        this.$defaultDuration = 5000;

        this.$items = this.$slider.find('[data-slider-items]');
        this.$item = this.$slider.find('[data-slider-item]');

        if ( !this.$items.length ) //|| this.$item.length < 2 )
            return;

        this.firstSlideInit();

        this.sliderHeight();

        this.$length = this.$item.length;
        this.$prev = this.$slider.find('[data-slider-prev]');
        this.$next = this.$slider.find('[data-slider-next]');
        this.$position = this.$slider.find('[data-slider-position]');

        $(window).on( 'resize', this.sliderHeight.bind(this) );
        this.swipeTouchDevices();

        if ( !this.$prev.length || !this.$next.length || !this.$position.length )
            return

        this.$prev.on( 'click', this.prevSlide.bind(this) );
        this.$next.on( 'click', this.nextSlide.bind(this) );
        
    },

    sliderHeight() {
        
        let $maxHeight = 0;
        let $active = this.$slider.find('.is-active');

        if ( $active ) {
            this.$items.height( 'auto' );
            $active.removeClass('is-active');
        }

        for ( let i = 0; i < this.$item.length; i++ ) {

            let $height = $(this.$item[i])[0].offsetHeight;

            if ( $height > $maxHeight )
                $maxHeight =  $height;

        }

        this.$items.height( $maxHeight );

        if ( $active )
            $active.addClass('is-active');

    },

    prevSlide() {

        let $active = this.$slider.find('.is-active');
        let $activeSlide = $active.data('slider-item');
        let $target = $activeSlide - 1;

        if ( $activeSlide == 1 )
            $target = this.$length;

        this.goToSlide( null, $target );

	},

	nextSlide() {

        let $active = this.$slider.find('.is-active');
        let $activeSlide = $active.data('slider-item');
        let $target = $activeSlide + 1;

        if ( $activeSlide == this.$length )
            $target = 1;
        
        this.goToSlide( null, $target );

	},

    goToSlide( e, $target ) {

        clearTimeout( this.$autoSlide );

        let $newSlide = this.$slider.find('[data-slider-item=' + $target + ']' );

        this.$item.removeClass('is-active');

        this.$autoSlide = setTimeout( () => { this.nextSlide() }, this.$defaultDuration );

        if ( $target < 10 ) {
            this.$position.html( '0' + $target );
        } else {
            this.$position.html( $target );
        }

        $newSlide.addClass('is-active');

    },

    swipeTouchDevices() {

        let $isTouchDevice = 'ontouchstart' in document.documentElement;

        let touchstartX = 0;
        let touchendX = 0;
            
        document.addEventListener('touchstart', e => {
            touchstartX = e.changedTouches[0].screenX;
        })

        document.addEventListener('touchend', e => {
            touchendX = e.changedTouches[0].screenX;
            this.checkDirection( touchstartX, touchendX );
        })
        
    },

    checkDirection( touchstartX, touchendX ) {

        if (touchendX < touchstartX) {
            Slider.nextSlide();
        }

        if (touchendX > touchstartX) {
            Slider.prevSlide();
        }

    },

    firstSlideInit() {

        let $firstSlide = this.$slider.find('[data-slider-item=1]' );

        $firstSlide.addClass('is-active');

        this.$slider.addClass('is-initialised');
        this.$autoSlide = setTimeout( () => { this.nextSlide() }, this.$defaultDuration );

    }
        
};

export default Slider;