
export const Steps = {

    init: function() {
        
        this.$steps = $('[data-steps]');
        this.$tabs = this.$steps.find('[data-steps-tab]');
        this.$panels = this.$steps.find('[data-steps-panel]');

        if ( !this.$steps.length || !this.$tabs.length || !this.$panels.length )
            return;

        this.$tabs.on( 'click', this.toggle.bind(this) );

    },

    toggle(e) {

        let $elem = $(e.currentTarget);

        if ( $elem.hasClass('is-active') )
            return;

        let $target = $elem.data('steps-tab');
        let $activePanel = this.$panels.parent().find('.is-active');
        let $active = this.$steps.find('.is-active');

        $active.removeClass('is-active');
        $elem.addClass('is-active');

        if ( $activePanel.length )
            $activePanel.fadeOut();

        let $new = this.$steps.find('[data-steps-panel="' + $target + '"]');

        if ( !$new.length )
            return;

        $new.fadeIn(300).css('display', 'flex');
        $new.addClass('is-active');

    }

};

export default Steps;

